.payment-method{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    .mback-btn{
        position: absolute;
        left: 1.875rem;
        top: 1.875rem;
        color: $dark100;
    }
    .hide-on-web{
        @media (min-width: 721px) {           
            display: none !important;      
        }
    }
}