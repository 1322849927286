.input-option-wrap{
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    height:40px;
    border-radius: 0.375rem;
    background-color: $light100;
    .btn{
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
    .dropdown-menu{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        min-width: 11rem;
        padding-top: 0.375rem;
        z-index: 1;
        .dropdown-main{
            display: flex;
            flex-direction: column;
            border-radius: 0.375rem;
            background: $white;
            box-shadow: 8px 8px 24px 0px rgba($color: $black, $alpha: 0.3);
            // backdrop-filter: blur(20px);
            overflow: hidden;
            .dropdown-item{

            }
            .active{
                background-color: $light100;
            }
        }
    }
}
.dropdown-checkbox-wrap{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    .btn{
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
    .dropdown-menu{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        min-width: 7rem;
        padding-top: 0.375rem;
        z-index: 99;
        .dropdown-main{
            display: flex;
            flex-direction: column;
            border-radius: 0.375rem;
            background: $white;
            box-shadow: 8px 8px 24px 0px rgba($color: $black, $alpha: 0.3);
            // backdrop-filter: blur(20px);
            overflow: hidden;
            .dropdown-item{
                display: flex;
                align-items: center;
                width: 100%;
                font-size: 0.875rem;
                font-weight: 500;
                color: $dark;
                padding: 0.875rem 1.25rem;
                border-bottom: 1px solid rgba($color: $dark100, $alpha: 0.1);
                .checkbox-field-item{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 1.375rem;
                    height: 1.375rem;
                    border-radius: 0.25rem;
                    background-color: $light100;
                    margin-right: 0.625rem;
                    position: relative;
                    cursor: pointer;
                    &.checked-item{
                        background-color: $primary;
                        cursor: pointer;
                    }
                    .checkbox-field{
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        cursor: pointer;
                    }
                }
            }
                
        }
    }
}
@media screen and (max-width: 767px) {
    .dropdown-wrap{
        margin-bottom: 1rem;
    }
    .dropdown-checkbox-wrap{
        
    }
}