.Page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 5.6rem;
  padding-bottom: 12rem;
  background-image: url('../../../img/bg-star-eligibility-left.svg'), url('../../../img/bg-star-eligibility-right.svg');
  background-repeat: no-repeat;
  background-position: top 11rem left, bottom -20rem right;
}

.section {
  background-color: #fff;
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 600px;
  width: 100%;
}

.section-h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.section-img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.section-p {
  font-size: 16px;
  color: #555;
}

.image{
  max-height: 200px;
  margin-bottom: 10px;
}
