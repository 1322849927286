.btn{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: $defaultfontfamily;
    padding: 0.625rem 1.25rem;
    border-radius: 6rem;
    font-size: 0.875rem;
    font-weight: 500;
    border: 1px solid transparent;
    cursor: pointer;
    text-decoration: none;
    height: 2.5rem;
    + .btn{
        margin-left: 1rem;
    }
    &.btn-primary{
        background-color: $primary;
        border-color: $primary;
        color: $white;
        &.btn-outline{
            background-color: transparent;
            border-color: $primary;
            color: $primary;
        }
    }
    &.btn-disabled{
        background-color: rgba($color: $primary, $alpha: 0.4);
        color: $white;
        cursor: not-allowed;
    }
    &.btn1{
        width:160px;
        &.btn-secondary{
            background-color: rgba(162, 244, 227, 0.2);
            border: 1px solid rgba(0, 0, 0, 0.2); 
            color: $dark100;
        }
    }
    &.buy-btn {
        width:170px;
        @media(min-width: 476px) and (max-width: 1400px) {
            max-width: 150px;
        }
        @media (max-width: 475px) {
            max-width: 140px;
        }
        &.btn-secondary{
            background-color: rgba(162, 244, 227, 0.2);
            border: 1px solid rgba(0, 0, 0, 0.2); 
            color: $dark100;
        }
    }

    &.fiat-buy-btn {
        width:170px;
        @media(min-width: 476px) and (max-width: 1400px) {
            max-width: 150px;
        }
        @media (max-width: 475px) {
            max-width: 140px;
        }
        &.btn-secondary{
            background-color: transparent;
            border: 1px solid rgba(0, 0, 0, 0.2); 
            color: $dark100;
        }
    }

    &.btn-secondary{
        background-color: $dark100;
        border-color: $dark100;
        color: $white;
        &.btn-outline{
            background-color: transparent;
            border-color: $dark100;
            color: $dark100;
        }
    }
    &.btn-white{
        background-color: $white;
        border-color: $white;
        color: $dark;
    }
    &.btn-danger{
        background-color: $danger100;
        border-color: $danger100;
        color: $white;
        &.btn-outline{
            background-color: transparent;
            border-color: $danger100;
            color: $danger100;
        }
    }
    &.btn-link-primary{
        display: inline-flex;
        padding: 0;
        border-radius: 0;
        border: 0px;
        color: $primary;
        height: auto;
        background-color: transparent;
        font-weight: 500;
        line-height: normal;
        text-decoration: underline;
    }
    &.btn-link-dark{
        padding: 0;
        border-radius: 0;
        border: 0px;
        color: rgba($color: $dark, $alpha: 0.5);
        height: auto;
        background-color: transparent;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
    }
    &.btn-link-black{
        padding: 0;
        border-radius: 0;
        border: 0px;
        background-color: transparent;
        color: $black;
        height: auto;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
    }
    .btn-icon-left{
        display: flex;
        margin-right: 0.625rem;
    }
    .btn-icon-right{
        display: flex;
        margin-left: 0.625rem;
    }
}
.action-button{
    .btn{
        padding-left: 2.75rem;
        padding-right: 2.75rem;
    }
}
.custom-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  /* Style for the arrow icon on the right */
  .arrow-icon {
    margin-left: auto; /* Push the icon to the right */
    padding-left: 8px; /* Add some spacing between text and icon */
  }
