.alert-wrapper{
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    margin-top: 0.625rem;
    opacity: 0;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
    @keyframes fadeInUp {
        from {
            transform: translate3d(0,40px,0);
            opacity: 0;
        }
    
        to {
            transform: translate3d(0,0,0);
            opacity: 1
        }
    }
    &.alert-primary{
        .alert-main{
            .icon-wrap{
            }
        }
    }
    &.alert-error{
        .alert-main{
            .icon-wrap{
            }
        }
    }
    &.alert-success{
        .alert-main{
            .icon-wrap{
            }
        }
    }
    .alert-main{
        display: flex;
        background-color: $dark100;
        color: $white;
        align-items: center;
        border-radius: 0.625rem;
        padding: 0.5rem 2.5rem 0.5rem 1.875rem;
        position: relative;
        font-size: 0.8125rem;
        .icon-wrap{
            background-color: rgba($color: $white, $alpha: 0.13);
            margin-right: 0.75rem;
            min-width: 2.25rem;
            width: 2.25rem;
            height: 2.25rem;
        }
        .alert-close{
            position: absolute;
            right: 1.875rem;
            top: 1.25rem;
            color: rgba($color: $white, $alpha: 0.6);
            svg{
                fill: $white;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .alert-wrapper{
        .alert-main{
            padding: 0.5rem 2.5rem 0.5rem 1rem;
            position: relative;
            font-size: 0.75rem;

            .icon-wrap{
                background-color: rgba($color: $white, $alpha: 0.13);
                margin-right: 0.75rem;

            }
            .alert-close{
                position: absolute;
                right: 1rem;
                top: 1.25rem;
                color: rgba($color: $white, $alpha: 0.6);
                svg{
                    fill: $white;
                }
            }
        }
    }
}