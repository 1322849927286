.kyc-detail-card{
    display: flex;
    flex-direction: column;
    background-color: rgba($color: $dark100, $alpha: 0.08);
    border-radius: 0.375rem;
    width: 100%;
    height: 100%;
    &.active{
        .kyc-detail-head{
            background-color: $dark;
            color: $white;
        }
    }
    .kyc-detail-head{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding: 1.125rem 1.25rem 0.8125rem;
        min-height: 9.125rem;
        border-radius: 0.375rem;
        background-color: rgba($color: $dark, $alpha: 0.1);
        .kyc-detail-head-title{
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 0.5rem;
            line-height: 1.6875rem;
        }
        .kyc-action{
            .btn{
                font-size: 0.875rem;
                height: 2rem;
            }
        }
    }
    .kyc-detail-content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1.25rem;
        width: 100%;
        height: 100%;
        .kyc-detail-lists{
            margin-bottom: 1rem;
            .kyc-detail-list-title{
                font-size: 0.875rem;
                font-weight: 500;
                line-height: 1.75rem;
                color: rgba($color: $dark, $alpha: 0.5);
                margin-bottom: 0.25rem;
            }
            .kyc-detail-list-ul{
                display: flex;
                flex-direction: column;
                width: 100%;
                margin: 0;
                padding: 0 0 0 1rem;
                li{
                    font-size: 0.875rem;
                    font-weight: 500;
                    line-height: 1.75rem;
                    color: $dark;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .kyc-detail-card{
        .kyc-detail-head{
            padding: 1rem;
            min-height: 10.125rem;
            border-radius: 0.375rem;
            .kyc-detail-head-title{
                font-size: 1rem;
                font-weight: 700;
                margin-bottom: 0.5rem;
                line-height: 1.6875rem;
            }
            .kyc-action{
                .btn{
                    font-size: 0.875rem;
                    height: 2rem;
                }
            }
        }
        .kyc-detail-content{
            padding: 1rem;
        }
    }
}

.kyc-modal {
    padding: 3rem 1.5rem;

    &--note {
        color: red;
        margin-bottom: 0;
    }

    &--accept {
        margin-top: 4rem;
        width: 100%
    }

    &--skip {
        background-color: transparent !important;
        border-color: transparent !important;
        color: #000 !important;
        margin-top: 10px;
        margin-left: 0 !important;
        width: 100%
    }
}